import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import "./index.css";

const urlParams = new URLSearchParams(window.location.search);
let inputGuid = urlParams.get("id");
let submissionId = urlParams.get("s");

// This flow is located here
// https://make.powerautomate.com/environments/4aaa7bbc-7830-4e1a-a6ca-41fedd367801/solutions/fd140aaf-4df4-11dd-bd17-0019b9312238/flows/f3cdef5e-7d41-4d87-9a29-476d042eddd8/details
fetch(
  "https://prod-117.westus.logic.azure.com/workflows/1ddce66a5d804ebda89e464b4bd38d37/triggers/manual/paths/invoke?api-version=2016-06-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=zbibtMTIWFmC4BNBPAABP41shQbolrMxoTyGkFlUt9w",
  // "https://webhook.site/e07f152f-5784-4e10-bc5c-f1de890b82eb",
  {
    method: "POST",
    headers: {
      "Content-Type": "application/json;charset=utf-8",
    },
    body: JSON.stringify({ companyGuid: inputGuid }),
    // mode: 'no-cors'
  }
)
  .then((response) => response.json())
  .then((data) => {
    // if (data.length !== 0) {
    //   console.log("data: ", data);
    // }

    const root = ReactDOM.createRoot(document.getElementById("root"));
    root.render(
      <React.StrictMode>
        <App submissionId={submissionId} company={data} />
      </React.StrictMode>
    );
  })
  .catch((err) => console.error(err));
