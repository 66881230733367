import "./App.css";
import React, { useReducer, useState } from "react";

const formReducer = (state, event) => {
  // console.log(state, event);
  return {
    ...state,
    [event.name]: event.value,
  };
};

function App(props) {
  const [formData, setFormData] = useReducer(formReducer, {});
  const [formSubmitted, setFormSubmitted] = useState(false);

  const late =
    new Date() > new Date(props.company.deadline.replaceAll("-", "/"));

  const handleSubmit = (event) => {
    event.preventDefault();

    if (props.submissionId) {
      formData["submissionId"] = props.submissionId;
    }

    formData["events"] = [];
    
    // Old
    // document.querySelectorAll(".event-checkbox:checked").forEach((value) => {
    //   const id = value.id.replace("event_", "");
    //   // if (props.eventList)
    //   formData["events"].push(id);
    // });

    // New
    formData["events"].push(props.company.eventList[0].eventItemId);

    console.log(formData);
    // This flow is located here
    // https://make.powerautomate.com/environments/4aaa7bbc-7830-4e1a-a6ca-41fedd367801/solutions/fd140aaf-4df4-11dd-bd17-0019b9312238/flows/a396e51a-8570-42d6-8bb0-94391ec71db3/details
    fetch(
      "https://prod-96.westus.logic.azure.com:443/workflows/cd98e991fce74cfe8ae066293c610988/triggers/manual/paths/invoke?api-version=2016-06-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=CFTDx9L6pmVCtyXqVzsY5_qHT_vMMsVDp4fnvt2dHoE",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
        // mode: 'no-cors' // no-cors is needed for webhook.site, but it cannot be included while sending to the power automate flow
      }
    );
    setFormSubmitted(true);
  };

  const handleChange = (event) => {
    const isCheckbox = event.target.type === "checkbox";
    setFormData({
      name: event.target.name,
      value: isCheckbox ? event.target.checked : event.target.value,
    });
  };

  return (
    <div className='app'>
      <div className='app-container'>
        <div className='app-header'>
          <a href='/' className='logo'>
            Schechter Logo
          </a>
        </div>
        <div className={"app-body" + (formSubmitted ? " invisible" : "")}>
          <p>Bootcamp Registration - {props.company.eventList[0].event}</p>
          <form onSubmit={handleSubmit}>
            <fieldset className={props.submissionId ? "invisible" : ""}>
              <label id='labName'>
                <p>NAME</p>
                <input
                  placeholder='First Name'
                  name='firstName'
                  onChange={handleChange}
                />
                <input
                  placeholder='Last Name'
                  name='lastName'
                  onChange={handleChange}
                />
              </label>
              <div id='divEmailPhone'>
                <label id='labEmail'>
                  <p>EMAIL</p>
                  <input
                    type='email'
                    placeholder='Email'
                    name='email'
                    onChange={handleChange}
                  />
                </label>
                <label id='labPhone'>
                  <p>MOBILE NUMBER</p>
                  <input
                    placeholder='Phone Number'
                    name='mobilePhoneNumber'
                    onChange={handleChange}
                  />
                </label>
              </div>
              <label id='labCompany'>
                <p>COMPANY</p>
                <input
                  placeholder='Company'
                  name='company'
                  onChange={handleChange}
                />
              </label>
              <label id='labInvitedBy'>
                <p>WHO INVITED YOU?</p>
                <input name='invitedBy' onChange={handleChange} />
              </label>
              {/* <p>
                What event activities will you be attending? (Check all to
                apply)
              </p>

              {props.company.eventList.map((value, idx) => (
                <label key={idx} className='checkbox-label'>
                  {new Date(value.startDateTime).toLocaleString("en-US", {timeZone: "America/New_York"})} -{" "}
                  {value.event}
                  <input
                    className='checkbox event-checkbox'
                    id={"event_" + value.eventItemId}
                    type='checkbox'
                    name={"_event_" + idx}
                    onChange={handleChange}
                  />
                  <span className='checkbox-span' htmlFor={"event" + idx} />
                </label>
              ))} */}
              <label id='dietaryRestrictionsList'>
                <p>Do you have any dietary restrictions?</p>
                <label className='checkbox-label'>
                  Vegan
                  <input
                    onChange={handleChange}
                    id='vegan'
                    type='checkbox'
                    name='vegan'
                    className='checkbox'
                  />
                  <span className='checkbox-span' htmlFor='vegan' />
                </label>
                <label className='checkbox-label'>
                  Vegetarian
                  <input
                    onChange={handleChange}
                    id='vegetarian'
                    type='checkbox'
                    name='vegetarian'
                    className='checkbox'
                  />
                  <span className='checkbox-span' htmlFor='vegetarian' />
                </label>
                <label className='checkbox-label'>
                  Gluten Free
                  <input
                    onChange={handleChange}
                    id='glutenFree'
                    type='checkbox'
                    name='glutenFree'
                    className='checkbox'
                  />
                  <span className='checkbox-span' htmlFor='glutenFree' />
                </label>
                <label className='checkbox-label'>
                  Allergy&#65121;
                  <input
                    onChange={handleChange}
                    id='allergy'
                    type='checkbox'
                    name='allergy'
                    className='checkbox'
                  />
                  <span className='checkbox-span' htmlFor='allergy' />
                </label>
                <input
                  onChange={handleChange}
                  id='allergyText'
                  name='allergyText'
                  className={formData.allergy ? "" : "invisible"}
                />

                <label className='checkbox-label'>
                  I do not have any dietary restriction
                  <input
                    onChange={handleChange}
                    id='dietOther'
                    type='checkbox'
                    name='noDietRestriction'
                    className='checkbox'
                  />
                  <span className='checkbox-span' htmlFor='dietOther' />
                </label>
                {/* <input
                  onChange={handleChange}
                  id='dietOtherText'
                  name='dietOtherText'
                  className={formData.dietOther ? "" : "invisible"}
                /> */}
              </label>
            </fieldset>
            <fieldset>
              <p>
                <b>TRAVEL INFORMATION</b>
              </p>
              <i>
                Schechter will cover guest transportation, hotel room, valet
                parking and event meal costs. However, invited guests are
                responsible for their airfare travel plans.
              </i>
              <label className={props.submissionId ? "invisible" : ""}>
                <p>Have you reserved your travel plans yet?</p>
                <label className='checkbox-label'>
                  YES&#65121;
                  <input
                    onChange={handleChange}
                    type='radio'
                    id='travelPlansYes'
                    name='travelPlans'
                    value='yes'
                    className='checkbox'
                  />
                  <span className='checkbox-span' htmlFor='travelPlansYes' />
                </label>
                <label className='checkbox-label'>
                  NO, please send me a follow up email about travel plans
                  <input
                    onChange={handleChange}
                    type='radio'
                    id='travelPlansNo'
                    name='travelPlans'
                    value='no'
                    className='checkbox'
                  />
                  <span className='checkbox-span' htmlFor='transportFlying' />
                </label>
                <p>How do you plan to travel?</p>
                <label className='checkbox-label'>
                  I will be flying to Birmingham, MI.
                  <input
                    onChange={handleChange}
                    type='radio'
                    id='transportFlying'
                    name='transport'
                    value='flying'
                    className='checkbox'
                  />
                  <span className='checkbox-span' htmlFor='transportDriving' />
                </label>
                <label className='checkbox-label'>
                  I will be driving to Birmingham, MI.
                  <input
                    onChange={handleChange}
                    type='radio'
                    id='transportDriving'
                    name='transport'
                    value='driving'
                    className='checkbox'
                  />
                  <span className='checkbox-span' htmlFor='travelPlansNo' />
                </label>
              </label>
              <div
                className={
                  formData.travelPlans === "yes" || props.submissionId
                    ? ""
                    : "invisible"
                }
              >
                <label>
                  <p>HOTEL CHECK-IN DATE</p>
                  <input
                    onChange={handleChange}
                    type='date'
                    name='hotelDateIn'
                  ></input>
                </label>
                <label>
                  <p>DEPARTURE DATE</p>
                  <input
                    onChange={handleChange}
                    type='date'
                    name='hotelDateOut'
                  ></input>
                </label>
                <label>
                  <p>WILL YOU NEED VALET?</p>
                  <label className='checkbox-label'>
                    YES
                    <input
                      onChange={handleChange}
                      type='radio'
                      id='valetYes'
                      name='valet'
                      value='yes'
                      className='checkbox'
                    />
                    <span className='checkbox-span' htmlFor='valetYes' />
                  </label>
                  <label className='checkbox-label'>
                    NO
                    <input
                      onChange={handleChange}
                      type='radio'
                      id='valetNo'
                      name='valet'
                      value='no'
                      className='checkbox'
                    />
                    <span className='checkbox-span' htmlFor='valetNo' />
                  </label>
                </label>
                <label id='flightInformationBlock'>
                  <p>FLIGHT INFORMATION</p>
                  <input
                    placeholder='Arrival flight number'
                    name='arrivalFlightNumber'
                    onChange={handleChange}
                  />
                  <input
                    placeholder='Departure flight number'
                    name='departureFlightNumber'
                    onChange={handleChange}
                  />
                </label>
              </div>
              <br />
              <br />
            </fieldset>
            <button id='btnSubmit' type='submit'>
              SUBMIT
            </button>
          </form>
        </div>
        <div
          className={"app-body" + (formSubmitted && !late ? "" : " invisible")}
        >
          <h2>Thank you for Regestering</h2>
          <h3>The form has been submitted!</h3>
          Please submit your travel itinerary to <a href='mailto:esmith@schechterwealth.com'>esmith@schechterwealth.com</a>
        </div>
        <div
          className={"app-body" + (formSubmitted && late ? "" : " invisible")}
        >
          <h3>You have missed the deadline for the event!</h3>
          Please reach out to{" "}
          <a href='mailto:esmith@schechterwealth.com'>
            esmith@schechterwealth.com
          </a>{" "}
          for more information.
        </div>
      </div>
    </div>
  );
}

export default App;
